import * as React from "react";

import { Error404Page } from "tabler-react";

// type Props = {||}

// function Error404(props: Props): React.Node {
//   return <Error404Page />
// }

function Error404() {
  return <Error404Page />
}

export default Error404
